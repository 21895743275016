<template>
  <div class="inventory-container">
    <div class="inventory-title">商品库存管理</div>
    <div class="queryItems mb20">
      <div class="u-start-flex mr15">
        <div class="default-font">SKU编码：</div>
        <el-input v-model="query.SKUCode" size="small" class="mr15" clearable style="width: 20rem" placeholder="SKU编码"></el-input>
      </div>
<!--      <div class="u-start-flex mr15">-->
<!--        <div class="default-font">商品品类：</div>-->
<!--        <el-select size="small" v-model="query.ProductId" clearable style="width: 20rem">-->
<!--          <el-option-->
<!--              v-for="item in productOption"-->
<!--              :key="item.Id"-->
<!--              :label="item.Name"-->
<!--              :value="item.Name">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </div>-->
      <div class="u-start-flex mr15">
        <div class="default-font">库存状态：</div>
        <el-select size="small" v-model="query.WareHouseId" clearable style="width: 20rem">
          <el-option
              v-for="item in wareHouseOption"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="u-start-flex mr15">
        <div class="default-font">所属仓库：</div>
        <el-select size="small" v-model="query.WareHouseId" clearable style="width: 20rem">
          <el-option
              v-for="item in wareHouseOption"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id">
          </el-option>
        </el-select>
      </div>
      <el-button type="primary" class="" size="small" icon="el-icon-search" @click="getData">查询</el-button>
    </div>
    <el-table
        :data="listData"
        :height="tableHeight"
        :max-height="tableHeight"
        size="mini"
        border
        style="width: 100%"
        v-loading="loading"
        element-loading-text="获取数据中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-table-column label="仓库" prop="WareHouseName" align="center"></el-table-column>
<!--      <el-table-column label="货架" prop="ShelvesName" align="center"></el-table-column>-->
<!--      <el-table-column label="商品品类" prop="ProductName" align="center"></el-table-column>-->
      <el-table-column label="SKU编码" prop="SKUCode" align="center"></el-table-column>
      <el-table-column label="图片" prop="ImagePath" align="center" width="60px">
        <template slot-scope="scope">
          <el-image class="sku-img" :preview-src-list="[scope.row.ImagePath]" :src="scope.row.ImagePath" v-if="scope.row.ImagePath"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="SKUName" align="center"></el-table-column>
      <el-table-column label="尺码" prop="Size" align="center"></el-table-column>
      <el-table-column label="型号" prop="Specification" align="center"></el-table-column>
      <el-table-column label="规格" prop="ModelNumber" align="center"></el-table-column>
      <el-table-column label="库存" prop="Quantity" align="center"></el-table-column>
    </el-table>
    <el-pagination
        class="mt20"
        background
        @size-change="handleSizeChange"
        @current-change="handleChange"
        :current-page="PageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="PageRows"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {getCODProduct, getCodSKUDetail} from "@/api/cod";

export default {
  data() {
    return {
      PageIndex: 1,
      PageRows: 10,
      productOption: [],
      wareHouseOption: [],
      query: {
        dateRange: []
      },
      total: 0,
      listData: [],
      loading: false
    }
  },
  computed: {
    tableHeight() {
      return 'calc(100vh - 21.6rem - 108px)'
    }
  },
  created() {
    this.getWareHouse()
    // this.getProduct()
    this.getData()
  },
  methods: {
    handleSizeChange(val) {
      this.PageRows = val
      this.PageIndex = 1;
      this.getData()
    },
    handleChange(current) {
      this.PageIndex = current;
      this.getData()
    },
    getWareHouse() {
      this.$store.dispatch('webSite/getWareHouse').then(data => {
        this.wareHouseOption = data
      })
    },
    getProduct() {
      const data = {
        PageIndex: 1,
        PageRows: 100,
      }
      getCODProduct(data).then(response => {
        const { Code, Msg, Data } = response
        if (Code === 200) {
          this.productOption = Data
        } else {
          this.$message.warning(Msg)
        }
      })
    },
    getData() {
      const data = {
        PageIndex: this.PageIndex,
        PageRows: this.PageRows,
        StartTime: this.query.dateRange.length ? this.query.dateRange[0] : undefined,
        EndTime: this.query.dateRange.length ? this.query.dateRange[1] : undefined,
        QueryCodeType: 14,
        QueryCodes: this.query.SKUCode ? [ this.query.SKUCode ] : undefined,
        ProductId: this.query.ProductId || undefined,
        WareHouseId: this.query.WareHouseId || undefined
      }
      this.loading = true
      getCodSKUDetail(data).then(response => {
        this.loading = false
        const { Code, Msg, Data, Total } = response
        if (Code === 200) {
          this.listData = Data
          this.total = Total
        } else {
          this.$message.warning(Msg)
        }
      })
    },
  }
}

</script>

<style scoped lang="scss">
.sku-img {
  width: 36px;
  height: 36px;
  border-radius: 5px;
}
.inventory-container {
  padding: 2rem;
  .inventory-title {
    padding: 1.5rem 2.5rem;
    color: #225493;
    background-color: #F0F4FB;
    font-size: 1.6rem;
    height: 1.6rem;
    margin-bottom: 1rem;
  }
  .queryItems {
    display: flex;
    flex-wrap: wrap;
    //justify-content: space-between;
    align-items: center;
  }
}
</style>